import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Box} from "@mui/material";

function SimpleSlider() {
    var settings = {
        infinite: true,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 10,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        cssEase: "linear",
        centeredSlides: true,
        draggable:false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Box sx={{justifyContent: "center", alignItems: "center"}}>

                <Slider {...settings}>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={'https://cdn.profi.ru/xfiles/pfiles/c77008ce3cae43f3bc402e36787157d4.jpg-profi_w1500.jpg'}/>
                    </Box>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={"https://cdn.profi.ru/xfiles/pfiles/5c9f2096919b4f44ac6650dea9eb017b.jpg-profi_w1500.jpg"}/>
                    </Box>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={'https://cdn.profi.ru/xfiles/pfiles/c46bdb974d3a48cd9c97cf55b2aa347d.jpg-profi_w1500.jpg'}/>
                    </Box>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={'https://cdn.profi.ru/xfiles/pfiles/d63f22a545e14b48a579cc200ad45758.jpg-profi_w1500.jpg'}/>
                    </Box>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={'https://cdn.profi.ru/xfiles/pfiles/50a51893882b4024ae05eff8d3ae03cd.jpg-profi_w1500.jpg'}/>
                    </Box>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={'https://cdn.profi.ru/xfiles/pfiles/56238728d5874723881d5c94c86ecded.jpg-profi_w1500.jpg'}/>
                    </Box>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={'https://cdn.profi.ru/xfiles/pfiles/42c6fc57352540929f35962cce629fdd.jpg-profi_w1500.jpg'}/>
                    </Box>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={'https://cdn.profi.ru/xfiles/pfiles/a0f6468256e74ea2aa6eba76f0cc55e8.jpg-profi_w1500.jpg'}/>
                    </Box>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={'https://cdn.profi.ru/xfiles/pfiles/665bd8ec5e8b42e5a09a15f6c2638adf.jpg-profi_w1500.jpg'}/>
                    </Box>
                    <Box sx={{justifyContent: "center", alignItems: "center"}}>
                        <img style={{maxWidth:"320px", maxHeight:'640px'}} src={'https://cdn.profi.ru/xfiles/pfiles/ac9e5370de164bed9612ee9bdcdc82f4.jpg-profi_w1500.jpg'}/>
                    </Box>
            </Slider>

        </Box>
    );
}

export default SimpleSlider;
