import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { FC } from "react";

export interface AccordionProps {
  header: string;
  description: string;
}
export const AccordionItem: FC<AccordionProps> = ({ header, description }) => {
  return (
    <Accordion sx={{ width: "100%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h1
          style={{
            fontSize: "20px",
            color: "#003366",
            fontWeight: "400",
          }}
        >
          {header}{" "}
        </h1>
      </AccordionSummary>
      <AccordionDetails>
        <h2
          style={{
            fontSize: "20px",
            color: "#003366",
            fontWeight: "300",
          }}
        >
          {description}
        </h2>{" "}
      </AccordionDetails>
    </Accordion>
  );
};
